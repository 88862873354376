@import url(https://fonts.googleapis.com/css?family=Poppins);
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: undefined;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: undefined;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}
button:hover {
  -webkit-filter: contrast(85%);
          filter: contrast(85%);
}
.ethereum {
  margin: -10%;
  cursor: pointer;
  border: none;
  background: none;
}

.zoom {
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.zoom:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
}
.lds-roller-after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.scrollable {
  height: calc(93vh - 120px);
  overflow-y: scroll;
}

.header {
  height: 9vh;
}

.name-header {
  min-width: 40px;
  text-align: right;
  margin-right: 10px;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.message-entry {
  min-height: calc(70vh - 120px);
  width: 100%;
  border: 1px solid #d9d9d9;
  outline: none;
  padding: 20px;
}

.margin-bottom {
  margin-bottom: 10px;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

html {
  scrollbar-width: none;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  font-family: 'Poppins', sans-serif;
}
.dg-capital-table-is-shareholder {
  color: #FF4C4F;
  background-color: #ffe5e5;
}
.dg-sider .ant-menu-item,
.dg-sider .ant-menu-submenu {
  border-left: 3px solid #242351;
  border-radius: 3px;
}
.dg-sider .ant-menu-item-selected {
  border-left: 3px solid #FF4C4F !important;
  background-color: #FFFFFF26 !important;
}
.dg-sider .anticon {
  font-size: 16px;
}
.dg-operation-description-container {
  overflow: hidden;
  border-radius: 3px;
}
.dg-operation-description-container .dg-operation-description-content {
  padding-left: 16px;
  border-left: 3px solid #FF4C4F !important;
}
.dg-flex {
  display: flex;
}
.dg-flex.dg-justify-between {
  justify-content: space-between;
}
.dg-flex.dg-align-center {
  align-items: center;
}
th.ant-table-cell {
  background-color: #F4F7FE !important;
  border-collapse: collapse;
  border: 0px !important;
}
.dg-new-operation .ant-menu-submenu-title {
  background: #FF4C4F !important;
  color: white;
  border-radius: 5px;
}
.dg-cursor {
  cursor: pointer;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: grey !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: grey !important;
}
.ant-alert-warning {
  background-color: #F4F7FE !important;
  border: 1px solid #bccef9 !important;
}

