@import (css) url(https://fonts.googleapis.com/css?family=Poppins);
@import "vars";

body {
    font-family: 'Poppins', sans-serif;
}

.dg-capital-table-is-shareholder {
    color: @primary-color;
    background-color: @primary-color-light;
}

.dg-sider {
    .ant-menu-item, .ant-menu-submenu {
        border-left: 3px solid @menu-dark-bg;
        border-radius: 3px;
    }

    .ant-menu-item-selected {
        border-left: 3px solid @primary-color !important;
        background-color: #FFFFFF26 !important;
    }

    .anticon {
        font-size: 16px;
    }
}

.dg-operation-description-container {
    overflow: hidden;
    border-radius: 3px;

    .dg-operation-description-content {
        padding-left: 16px;
        border-left: 3px solid @primary-color !important;
    }
}

.dg-flex {
    display: flex;

    &.dg-justify-between {
        justify-content: space-between;
    }

    &.dg-align-center {
        align-items: center;
    }
}

th.ant-table-cell {
    background-color: @fourth-color !important;
    border-collapse: collapse;
    border: 0px !important;
}

.dg-new-operation .ant-menu-submenu-title {
    background: @primary-color !important;
    color: white;
    border-radius: 5px;
}

.dg-cursor {
    cursor: pointer;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: grey !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: grey !important;
}

.ant-alert-warning {
	background-color: @fourth-color !important;
	border: 1px solid darken(@fourth-color, 12%) !important;
}
