.ethereum {
  margin: -10%;
  cursor: pointer;
  border: none;
  background: none;
}

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  transform: scale(1.2);
}
