.scrollable {
  height: calc(93vh - 120px);
  overflow-y: scroll;
}

.header {
  height: 9vh;
}

.name-header {
  min-width: 40px;
  text-align: right;
  margin-right: 10px;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.message-entry {
  min-height: calc(70vh - 120px);
  width: 100%;
  border: 1px solid #d9d9d9;
  outline: none;
  padding: 20px;
}

.margin-bottom {
  margin-bottom: 10px;
}
